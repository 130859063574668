import { Link } from "gatsby"
import React from "react"
import LayoutAlt from "../../components/LayoutAlt"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../../components/seo"

export default function Renewables() {
  return (
    <LayoutAlt>
      <Seo
        title="Renewables"
        description="CAST Rope Access is a South African turnkey rope access service
              provider to the Renewables sector."
        pathname="/capabilities/renewables"
      />
      <section className="hero hero--renewables px-4 bg-brand-blue flex items-center relative">
        <StaticImage
          className="absolute top-0 right-0 bottom-0 left-0 z-0 h-full w-full object-cover"
          src="../../images/hero-renewables-v2.jpg"
          alt="Renewables"
          placeholder="blurred"
        />
        <div className="container mx-auto relative z-10">
          <div className="hero__content text-center">
            <h1 className="text-white mx-auto mb-6">Renewables</h1>
            <p className="intro text-white lg:text-lg font-semibold tracking-wider mx-auto mb-8 lg:w-1/2">
              CAST Rope Access is a South African turnkey rope access service
              provider to the Renewables sector.
            </p>
            <Link className="btn btn--renewables" to="/contact-us">
              Contact Us
            </Link>
          </div>
        </div>
      </section>
      <section className="capability-details px-4 py-24">
        <div className="container mx-auto md:grid md:grid-cols-2 md:gap-8">
          <div className="capability-details__item">
            <div className="grid grid-cols-2 gap-4 mb-6">
              <StaticImage
                className="border-4 border-capability-blue shadow-xl rounded-xl"
                src="../../images/renewables-01.jpg"
                alt="Renewables"
                placeholder="blurred"
              />
              <StaticImage
                className="border-4 border-capability-blue shadow-xl rounded-xl"
                src="../../images/renewables-02.jpg"
                alt="Renewables"
                placeholder="blurred"
              />
            </div>
            <h3 className="text-capability-blue mb-6">
              WIND TURBINE INSPECTIONS, REPAIRS &amp; MAINTENANCE
            </h3>
            <ul className="list-disc pl-6 mb-6">
              <li>UAV Inspections.</li>
              <li>
                Rotor Blade Close Up Visual Inspections, such as LP & LEP.
              </li>
              <li>Inspect Nacelle Housing, TP & MP.</li>
              <li>Corrosion Surveys and conditional assessments.</li>
              <li>Servicing Turbine Brakes.</li>
              <li>Oil ﬁlter change out.</li>
              <li>Leading Edge Protection.</li>
              <li>Gel Coat & Laminate Repairs.</li>
              <li>Lightening Strike Repairs.</li>
              <li>Wind Deﬂector Replacement.</li>
              <li>Bolt Torque & Tension services.</li>
              <li>Corrosion Surveys and Condition Assessments.</li>
              <li>Coating errosion and bird strike repairs.</li>
            </ul>
            <span className="mb-6 border-b-2 border-capability-blue inline-block h-1 w-16"></span>
          </div>
          <div className="capability-details__item">
            <div className="grid grid-cols-2 gap-4 mb-6">
              <StaticImage
                className="border-4 border-capability-blue shadow-xl rounded-xl"
                src="../../images/renewables-05.jpg"
                alt="Renewables"
                placeholder="blurred"
              />
              <StaticImage
                className="border-4 border-capability-blue shadow-xl rounded-xl"
                src="../../images/renewables-06.jpg"
                alt="Renewables"
                placeholder="blurred"
              />
            </div>
            <h3 className="text-capability-blue mb-6">
              WIND FARMS - GREEN FIELDS &amp; CONSTRUCTION PHASE
            </h3>
            <ul className="list-disc pl-6 mb-6">
              <li>Cable Pull-In</li>
              <li>Installation of CPS </li>
              <li>Messenger Wire Retrieval</li>
              <li>Mini ROV Operators</li>
              <li>Inner Array & Export Cables</li>
              <li>Temporary & Permanent Hang-Off Installation</li>
              <li>
                Cable Stripping, Routing & Cleating HV Cores and FO Cables to
                Switchgear
              </li>
              <li>Termination & Splicing FO Cable</li>
              <li>Post Installation Testing - IR, TDR & OTDR</li>
              <li>Anode Installation and Grouting</li>
              <li>Advanced Confined Space Rescue</li>
            </ul>
            <span className="mb-6 border-b-2 border-capability-blue inline-block h-1 w-16"></span>
          </div>
        </div>
        <div className="text-center">
          <Link className="btn btn--renewables" to="/contact-us">
            Contact Us
          </Link>
        </div>
      </section>
    </LayoutAlt>
  )
}
